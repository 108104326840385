import { Component, EventEmitter, HostListener, Output } from '@angular/core';
import {faHouse, faAddressCard, faFile, faChevronRight, faSignOut,} from '@fortawesome/free-solid-svg-icons';
import { SectionService } from 'src/app/services/section.service';
import { Section } from 'src/app/types/section';
import {Router} from "@angular/router";
import { ResponsiveService } from 'src/app/services/responsive.service';

@Component({
  selector: 'app-aside-menu',
  templateUrl: './aside-menu.component.html',
  styleUrls: ['./aside-menu.component.scss']
})
export class AsideMenuComponent {

  @Output() onToggleSidebar: EventEmitter<boolean> = new EventEmitter();

  faHouse = faHouse;
  faAddressCard = faAddressCard
  faFile = faFile;
  faChevronRight = faChevronRight;

  isSidebarOpen?: boolean;

  constructor(
    private _responsiveService: ResponsiveService,
    private sectionService: SectionService,
    private router: Router) {

  }

  get responsiveService() {
    return this._responsiveService;
  }

  ngOnInit() {
    this.isSidebarOpen = this._responsiveService.deviceDesktop();
  }

  setCurrentSection(section: Section) {
    this.onToggleSidebar.emit()
    this.sectionService.updateCurrentSection(section);
  }

  logout() {
    localStorage.removeItem('SILI_FAM_TOKEN')
    localStorage.removeItem('SILI_FAM_TOKEN');
    this.router.navigateByUrl('/login');
  }

  toggleSidebar() {
    this.onToggleSidebar.emit()
  }

  protected readonly faSignOut = faSignOut;
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NavbarComponent } from './navbar/navbar.component';
import { LayoutComponent } from './layout/layout.component';
import { AsideMenuComponent } from './aside-menu/aside-menu.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SiliFamModalComponent } from './sili-fam-modal/sili-fam-modal.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { NgSelectModule } from '@ng-select/ng-select';
import { SliderComponent } from './slider/slider.component';


@NgModule({
  declarations: [
    NavbarComponent,
    LayoutComponent,
    AsideMenuComponent,
    SiliFamModalComponent,
    SliderComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FontAwesomeModule,
    NgxSmartModalModule.forChild(),
    NgScrollbarModule,
    NgSelectModule,
    SweetAlert2Module.forRoot()
  ],
  exports: [
    NavbarComponent,
    LayoutComponent,
    SiliFamModalComponent,
    SliderComponent,
    NgxSmartModalModule,
    NgScrollbarModule,
    SweetAlert2Module
  ]
})
export class SharedModule { }

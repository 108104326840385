import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, SimpleChange, SimpleChanges, ViewChild } from '@angular/core';
import { ResponsiveService } from '../../services/responsive.service';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Tab } from "../../types/tabs"

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent {

  @Input() tabs: Tab[] = [];
  @Input() activeTab: string = "";
  @Input() tabHeight?: "s" | "m" | "l" = "m";

  @Output() onChangeTab: EventEmitter<string> = new EventEmitter();

  @ViewChild('slider') slider?: ElementRef;

  tabsWidth: number = 0;
  faChevronLeft = faChevronLeft;
  faChevronRight = faChevronRight;

  ngAfterViewChecked() {
    const tabElements = this.slider?.nativeElement.querySelectorAll(".tab") as NodeListOf<HTMLElement>;
    // * tab width + 5px gap - 2px last border right
    this.tabsWidth = Array.from(tabElements).reduce((acc, tab) => acc + Math.floor(tab.getBoundingClientRect().width), 0) + (this.tabs.length * 5) - 2; 
    this.changeDetectorRef.detectChanges();
  }

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private _responsiveService: ResponsiveService
  ) { }
  
  get responsiveService() {
    return this._responsiveService;
  }

  get windowWidth() {
    return window.innerWidth - ( (this.responsiveService.deviceMobile() ? 0 : 200) + 32)
  }

  slideToLeft(slider: HTMLElement) {
    console.warn('SLIDE LEFT', slider, slider.scrollLeft)


    slider.scrollLeft -= 200;
  }

  slideToRight(slider: HTMLElement) {
    console.warn('SLIDE RIGHT', slider, slider.scrollLeft)
    slider.scrollLeft += 200;
  }

  setActiveTab(tabName: string) {
    this.activeTab = tabName;
    this.onChangeTab.emit(this.activeTab);

    this.slideToActive();
      
  }

  slideToActive() {
    // * on tab change, scroll the container until the selected tab is at the center
    const selectedTabIndex = this.tabs.findIndex(tab => tab.name === this.activeTab);
    const tabElements = this.slider?.nativeElement.querySelectorAll(".tab") as NodeListOf<HTMLElement>;
    const tabsWidthUntilSelected = Array.from(tabElements).slice(0, selectedTabIndex).reduce((acc, tab) => acc + tab.getBoundingClientRect().width + 5, 0);

    // * get the selected tab position from the center of the parent
    const containerWidth = this.slider?.nativeElement.offsetWidth;
    const selectedTabWidth = tabElements[selectedTabIndex].getBoundingClientRect().width;
    const scrollLeft = tabsWidthUntilSelected - (containerWidth / 2) + (selectedTabWidth / 2);

    this.slider!.nativeElement.scrollLeft = scrollLeft;
  } 

}

<div class="slider-outer">
    <div class="slider-button left" *ngIf="windowWidth < tabsWidth " (click)="slideToLeft(slider)">
        <fa-icon [icon]="faChevronLeft"></fa-icon>
    </div>
    <div class="slider" #slider>
        <div 
        *ngFor="let tab of tabs" 
        (click)="setActiveTab(tab.name)"
        class="tab" 
        [ngClass]="{
            'active': activeTab === tab.name, 
            's' : tabHeight === 's',
            'm' : tabHeight === 'm',
            'l' : tabHeight === 'l',
        }">
        {{ tab.label }}
        </div>
    </div>
    <div class="slider-button right" *ngIf="windowWidth < tabsWidth" (click)="slideToRight(slider)">
        <fa-icon [icon]="faChevronRight"></fa-icon>
    </div>
</div>
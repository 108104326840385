import { Component, OnInit } from '@angular/core';
import { map, Observable, tap } from 'rxjs';
import { ResponsiveService } from 'src/app/services/responsive.service';
import { UserService } from 'src/app/services/user.service';
import { ActiveUser } from 'src/app/types/active-users';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
 
  user$?: Observable<ActiveUser>
  showSidebar?: boolean;


  constructor(
    private _responsiveService: ResponsiveService,
    private userService: UserService) {
  }

  get responsiveService() {
    return this._responsiveService
  }

  ngOnInit(): void {
    this.showSidebar = this.responsiveService.deviceDesktop();
    this.getLoggedUserData();
  }

  getLoggedUserData() {
    this.userService.getLoggedUser().subscribe({
      next: (user: ActiveUser) => this.userService.setActiveUser(user)
    });
  }

  toggleSidebar() {
    this.showSidebar = !this.showSidebar;
  }
}

import { ChangeDetectorRef, Component, ViewChild, ViewContainerRef } from '@angular/core';
import { DynamicFormModel } from 'src/app/types/dynamic-form';

@Component({
  selector: 'app-sili-fam-modal',
  templateUrl: './sili-fam-modal.component.html',
  styleUrls: ['./sili-fam-modal.component.scss']
})


export class SiliFamModalComponent {


  @ViewChild("bodyTemplate", { read: ViewContainerRef }) templateRef!: ViewContainerRef;

  constructor(
    private cd: ChangeDetectorRef
  ) { }

  modalTitle: string = ""
  formName: string = ""
  template?: any;
  model: DynamicFormModel[] = [];
  renderFormComponent : boolean = false;
  values: any = {}

  ngAfterViewInit() {
    if(this.renderFormComponent)
      this.registrerDynamicForm()
  }


  private registrerDynamicForm() {
    this.templateRef.clear();
    const componentInstance = this.template;
    // console.log(this.formName)
    console.log(this.values)
    const dynamicComponent = this.templateRef.createComponent(componentInstance);
    dynamicComponent.setInput('model', this.model);
    dynamicComponent.setInput('formName', this.formName);
    dynamicComponent.setInput('values', this.values);



    this.cd.detectChanges();
  }
}

import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class SwalService {

  constructor() { }

  showSuccessMessage(text: string, title?: string, timerMS?: number) {
    Swal.fire({
      icon: "success",
      text,
      showConfirmButton: false,
      showCloseButton: false,
      ...(title ? { title } : {}),
      ...(timerMS ? { timer: timerMS } : {})
    })
  }

  showErrorMesssage(text: string, title?: string) {
    Swal.fire({
      icon: 'error',
      text,
      showConfirmButton: false,
      showCloseButton: false,
      ...(title ? { title } : {}),
    })
  }

  showConfirmMessage(text: string, title?: string) {
    return Swal.fire({
      icon: "warning",
      text,
      showConfirmButton: true,
      showCancelButton: true,
      showCloseButton: true,
      confirmButtonText: "Conferma",
      cancelButtonText: "Annulla",
      confirmButtonColor: "#ef233c",
      cancelButtonColor: "#12BA92",
      ...(title ? { title } : {})
    })
  }
}

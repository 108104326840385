import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';

export const AuthGuard: CanActivateFn = (route, state) => {
  const isUserLoggedIn = inject(AuthenticationService).isLoggedIn();
  console.warn('IS USER LOGGED IN', isUserLoggedIn);

  const router = inject(Router);
  return inject(AuthenticationService).isLoggedIn() ? true : router.navigateByUrl('/login');
};

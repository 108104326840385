import { Injectable } from '@angular/core';
import { LoginCredentials } from '../models/login';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { map, shareReplay, tap } from 'rxjs';
import { AuthResponse } from '../types/authentication';
import { Router } from '@angular/router';
import * as moment from "moment";

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(
    private router: Router,
    private http: HttpClient
  ) { }

  login(loginCredentials: LoginCredentials) {
    return this.http.post<LoginCredentials>(`${environment.siliFamBackend}/api/v1/auth/login`, loginCredentials)
  }

  refreshToken(refreshToken: { refresh_token: string }) {
    return this.http.post(`${environment.siliFamBackend}/api/v1/auth/refresh-token`, refreshToken)
  }

  logout() {
    localStorage.removeItem('SILI_FAM_TOKEN');
    localStorage.removeItem('SILI_FAM_TOKEN_EXPIRE_IN');

    this.router.navigate(['/login']);
  }

  generateToken(loginCredentials: LoginCredentials) {
    return this.http.post<LoginCredentials>(`${environment.siliFamBackend}/api/v1/auth/generate-token`, loginCredentials).pipe(
      map( (response: any) => ({ token: response.token, refresh_token: response.refresh_token })),
      tap( (authResult: AuthResponse) => this.storeToken(authResult)),
      shareReplay()
    )
  }

  storeToken(authToken: AuthResponse) {
    console.warn('AUTH TOKEN', authToken);
    localStorage.setItem('SILI_FAM_TOKEN', authToken.token);
    localStorage.setItem('SILI_FAM_REFRESH_TOKEN', authToken.refresh_token)
  }

  getRefreshToken() {
    return localStorage.getItem("SILI_FAM_REFRESH_TOKEN");
  }

  getToken() {
    return localStorage.getItem("SILI_FAM_TOKEN");
  }

  isLoggedIn() {
    return !!localStorage.getItem("SILI_FAM_TOKEN");
  }

  isLoggedOut() {
    return !this.isLoggedIn();
  }

  getTokenExpiration() {
    const expiration = JSON.stringify(localStorage.getItem("SILI_FAM_TOKEN_EXPIRE_IN"));

    if(expiration) {
      const expiresAt = JSON.parse(expiration);
      return expiresAt;
    }

    return moment().valueOf();
  } 

}

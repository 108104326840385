import { Injectable } from '@angular/core';
import { ExternalUser } from '../types/external-user';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { ActiveUser } from '../types/active-users';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private activeUserSubject$ = new BehaviorSubject<ActiveUser | undefined>(undefined);

  constructor(
    private http: HttpClient
  ) { }

  createExternalUser(registryId: number, externalUser: ExternalUser) {

    const body = {
      externalUser
    }

    return this.http.post(`${environment.siliFamBackend}/api/v1/users/anagrafica/${registryId}/external-user`, body)
  }

  setActiveUser(user: ActiveUser) {
    this.activeUserSubject$.next(user)
  }

  getActiveUser(): Observable<ActiveUser | undefined> {
    return this.activeUserSubject$?.asObservable();
  }

  getLoggedUser() {
    return this.http.get(`${environment.siliFamBackend}/api/v1/users/user`).pipe(
      map( (response: any) => response.user)
    )
  }
}

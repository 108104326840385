import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, catchError, map, switchMap, tap, throwError } from "rxjs";
import { AuthenticationService } from "../services/authentication.service";
import { AuthResponse } from "../types/authentication";
import { Router } from "@angular/router";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService) {

    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError( (error: HttpErrorResponse ) => {

                console.error(error);

                if(error.status === 401) {
                    console.warn("INTERCEPT REFRESH TOKEN")
                    return this.handleRefreshToken(request, next);
                }

                if(error.status === 403) {
                    console.warn('NOT VALID TOKEN');
                    this.handleLogout();
                }

                return throwError(() => {
                    return error;
                })
            })
        )
    }

    handleRefreshToken(request: HttpRequest<any>, next: HttpHandler) {

        const refreshToken = this.authenticationService.getRefreshToken();

        if(!refreshToken)
            throw new Error("Refresh token not found")
        
        const params = {
            refresh_token: refreshToken
        };

        return this.authenticationService.refreshToken(params).pipe(
            switchMap( (response: any) => {

                const { token: refreshedToken } = response;

                localStorage.setItem("SILI_FAM_TOKEN", refreshedToken);

                const clonedRequest = request.clone({
                    headers: request.headers.set('Authorization', `Bearer ${refreshedToken}`)
                })
    
                return next.handle(clonedRequest);
            }),
            catchError(( error: any ) => {
                return throwError(() => {
                    this.router.navigateByUrl('/login');
                    return error;
                })
            })
        )
    }

    handleLogout() {
        localStorage.removeItem('SILI_FAM_TOKEN');
        this.router.navigateByUrl('/login');
    }

}